<script setup lang="ts">
import type { Api } from '@rialtic/api'
import { useWorkspace } from '~/stores/workspace'

const props = defineProps<{
  active?: boolean
  workspace: Api.Workspace
}>()

const emit = defineEmits(['done'])

const workspaceStore = useWorkspace()

const setWorkspace = async () => {
  await workspaceStore.switchWorkspace(props.workspace.id)
  emit('done')
}
</script>

<template>
  <button
    data-component="workspace_card"
    :name="workspace.name"
    class="card flex h-full w-full flex-col border border-2 p-4"
    :class="{
      'bg-primary-50 border-primary': active,
      'hover:bg-primary-50 border-transparent': !active,
    }"
    @click="setWorkspace()"
  >
    <div class="text-left">
      <img
        v-if="workspace.logo"
        class="mb-4 h-10 w-40"
        :alt="`logo for ${workspace.name}`"
        :src="workspace.logo"
      />

      <div v-else class="mb-2 h-10 w-40 rounded bg-neutral-300" />
      <h2 class="h3 text-neutral-700" :class="{ 'text-primary-dark': active }">
        {{ workspace.name }}
      </h2>
    </div>
  </button>
</template>
